import React from 'react'
import { Link } from 'react-router-dom'
import { UncontrolledDropdown, DropdownToggle, Media, DropdownMenu, DropdownItem } from 'reactstrap'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Button } from '../../../components/custom/button/Button'
import { Spinner } from '../../../components/custom/spinner/Spinner'
import _ from 'lodash'
import { useLocation } from 'react-router-dom'
import { modifiedNameManu } from '../../../configs/modifiedMenuNameConfig.jsx'

export const GetModule = (props) => {
  const MODULES = JSON.parse(localStorage.getItem('MODULES'))
  let activePathArr = useLocation().pathname.split('/')
  const moduleName = 'เลือกรายการ'

  const findCurrentMenuCode = (pathname, modules) => {
    if (modules) {
      const subModulesAfterFilter = modules[0].level1.find((subModule) => {
        if (subModule.level2?.length > 0) {
          const subModuleLv2AfterFilter = subModule.level2.find((subModuleLv2) => subModuleLv2.menuPath === pathname)

          if (subModuleLv2AfterFilter) {
            return true
          }
        }
        return subModule.menuCode === pathname
      })

      return subModulesAfterFilter
    } else {
      return null
    }
  }

  let currentMenuCode = null

  while (activePathArr.length > 1) {
    const activePath = activePathArr[activePathArr.length - 1]
    currentMenuCode = findCurrentMenuCode(activePath, MODULES)
    if (currentMenuCode != null) {
      break
    } else {
      activePathArr.pop()
    }
  }

  function Module() {
    let dataObjNameManu
    return (
      <>
        {!MODULES ? (
          <Spinner color="primary" />
        ) : (
          MODULES[0].level1.map((item, key) => {
            dataObjNameManu = modifiedNameManu(item.menuNameTH, item.menuCode)
            return (
              <div
                className={`d-flex justify-content-between body-list-modules ${
                  currentMenuCode?.menuCode === item.menuCode ? 'active' : ''
                }`}
                key={key}
              >
                <Link
                  to={`/${item.menuCode}`}
                  onClick={() => {
                    document.getElementById('btn-mainmanu').click() //For hide main menu
                  }}
                >
                  <Media className="d-flex align-items-start">
                    <Media style={{ width: '30px' }} left href="">
                      <div style={{ fontSize: 20, color: '#267659' }}>
                        <i className={`${item.icon}`} />
                      </div>
                    </Media>
                    <Media body>
                      <Media heading className="media-heading" style={{ color: '#267659' }} tag="h5">
                        {dataObjNameManu?.nameMenuHeader}
                      </Media>
                      <small className="notification-text" style={{ color: '#666666' }}>
                        {dataObjNameManu?.nameMenuSubHeader}
                      </small>
                    </Media>
                  </Media>
                </Link>
              </div>
            )
          })
        )}
      </>
    )
  }
  return (
    <>
      <UncontrolledDropdown tag="li" className="dropdown-notification nav-item ">
        <DropdownToggle tag="a" className="nav-link nav-link-label pt-0 pb-0 pl-0 pr-0">
          <Button id="btn-mainmanu" type="mainmanu" icon="fas fa-align-justify" height="40" width="180">
            {moduleName}
          </Button>
        </DropdownToggle>
        <DropdownMenu tag="ul" className="dropdown-menu-media dropdown-menu-left pt-0 mt-1">
          <li className="dropdown-menu-header">
            <div className="dropdown-header mt-0">
              <h4 className="text-white">เมนูหลัก</h4>
            </div>
          </li>
          <PerfectScrollbar
            className="media-list overflow-hidden position-relative"
            options={{
              wheelPropagation: false
            }}
          >
            <Module />
          </PerfectScrollbar>
          <li className="dropdown-menu-footer">
            <DropdownItem tag="a" className="p-1 text-center">
              <span className="align-middle" style={{ color: '#bdbfc1' }}>
                Bangkok of Education Management Information System
              </span>
            </DropdownItem>
          </li>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  )
}
