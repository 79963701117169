import React from "react"
import { DragDropContext, Droppable } from "react-beautiful-dnd"
import {GetModule} from "./GetModule"

// a little function to help us with reordering the bookmarks
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

class NavbarBookmarks extends React.PureComponent {
  state = {
    showBookmarks: false,
    value: "",
    noSuggestions: false,
    isStarred: false,
    suggestions: [],
    starredItems: [],
  }

  updateBookmarks = false

  handleBookmarksVisibility = () => {
    this.setState({
      showBookmarks: !this.state.showBookmarks,
      value: "",
      suggestions: [],
      noSuggestions: false,
      starred: null,
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.bookmarks.starred.length !== this.state.starredItems.length && this.updateBookmarks === true) {
      this.setState({ starredItems: this.props.bookmarks.starred })
      this.updateBookmarks = false
    }
  }

  onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const starredItems = reorder(this.state.starredItems, result.source.index, result.destination.index)

    this.setState({
      starredItems,
    })
  }

  renderBookmarks = () => {
    this.updateBookmarks = true
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.droppableProps} className="d-flex flex-sm-wrap flex-lg-nowrap draggable-cards">
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    )
  }

  render() {
    return (
      <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
        <ul className="nav navbar-nav d-lg-none">
        </ul>
        <ul className="nav navbar-nav bookmark-icons" >
          <GetModule></GetModule>
        </ul>
      </div>
    )
  }
}

export default NavbarBookmarks
