import React from "react";
import { Theme } from "../../../styles";
import {
  // Eclipse,
  // Spinner as Spinners,
  Rolling,
  // DualRing,
  // Ball,
  // Spin
} from "react-loading-io";
import { DivPositionFixed } from "./PopupLoadingStyled.jsx";

export const PopupLoading = props => {
  return (
    <DivPositionFixed>
      <div className="content-loading">
        {props.loading === "dataIframe" ? (
          <>
            <Rolling size={64} width={10} style={{ marginLeft: "7px" }} color={Theme.Color_SeaGreen2} />
            <div className="loading-text" style={{ width: "94px", color: Theme.Color_SeaGreen2 }}>
              {(() => {
                if (typeof props.text === "undefined") {
                  return "กรุณารอสักครู่"
                } else if (props.text) {
                  return props.text
                }
              })()}
            </div>
          </>
        ) : (
          <>
            <Rolling size={64} width={10} color={Theme.Color_SeaGreen2} />
            <div className="loading-text" style={{ color: Theme.Color_SeaGreen2 }}>
              {(() => {
                if (typeof props.text === "undefined") {
                  return "Loading"
                } else if (props.text) {
                  return props.text
                }
              })()}
            </div>
          </>
        )}
      </div>
    </DivPositionFixed>
  );
};



