import React from "react";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import * as Icon from "react-feather";
import { history } from "../../../history";
import { useAuth } from "../../../utility/context/Auth";

const handleNavigation = (e, path) => {
  e.preventDefault();
  history.push(path);
};

const UserDropdown = (props) => {
  const { logout } = useAuth();
  const logOut = () => {
    logout();
  };

  const USERPROF = JSON.parse(localStorage.getItem("USERPROF"));
  const mainSystem = process.env["REACT_APP_PORTAL_URL"]
    ? process.env["REACT_APP_PORTAL_URL"]
    : "#";
  const loginSystem = process.env["REACT_APP_PORTAL_LOGIN"]
    ? process.env["REACT_APP_PORTAL_LOGIN"]
    : "#";

  return (
    <DropdownMenu right>
      <DropdownItem
        tag="a"
        href={`${process.env["REACT_APP_PORTAL_URL"]}/ChangePassword`}
      >
        <Icon.Key style={{ color: "#626262" }} size={14} className="mr-50" />
        <span style={{ color: "#626262" }} className="align-middle">
          เปลี่ยนรหัสผ่าน
        </span>
      </DropdownItem>
      <DropdownItem tag="a" href={mainSystem} onClick={logOut}>
        <Icon.Home style={{ color: "#626262" }} size={14} className="mr-50" />
        <span style={{ color: "#626262" }} className="align-middle">
          กลับไปหน้าระบบหลัก (หน้าแรก)
        </span>
      </DropdownItem>
      <DropdownItem tag="a" href={loginSystem} onClick={logOut}>
        <Icon.Power style={{ color: "#626262" }} size={14} className="mr-50" />
        <span style={{ color: "#626262" }} className="align-middle">
          ออกจากระบบ
        </span>
      </DropdownItem>

      {/* <DropdownItem divider />
      <DropdownItem
        tag="a"
        href={mainSystem}
        // href={USERPROF ? `http://${USERPROF.WebPortal}/system` : '#'}
        onClick={logOut}
      >
        <Icon.Power style={{ color : '#626262' }} size={14} className="mr-50" />
        <span  style={{ color : '#626262' }} className="align-middle">ออกจากระบบ</span>
      </DropdownItem> */}
    </DropdownMenu>
  );
};

class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    langDropdown: false,
    suggestions: [],
  };

  componentDidMount() {
    // axios.get("/api/main-search/data").then(({ data }) => {
    //   this.setState({ suggestions: data.searchResult })
    // })
  }

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch,
    });
  };

  handleLangDropdown = () =>
    this.setState({ langDropdown: !this.state.langDropdown });

  render() {
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <UncontrolledDropdown
          tag="li"
          className="dropdown-notification nav-item"
        >
          <DropdownToggle tag="a" className="nav-link nav-link-label">
            <div style={{ fontSize: 20, color: "#FFFFFF" }}></div>
          </DropdownToggle>
        </UncontrolledDropdown>
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {localStorage.getItem("USER_FNAME") +
                  " " +
                  localStorage.getItem("USER_LNAME")}
              </span>
              <span className="user-status">
                {localStorage.getItem("USER_EMAIL")}
              </span>
            </div>
            <span data-tour="user">
              <img
                src={this.props.userImg}
                className="round"
                height="40"
                width="40"
                alt="avatar"
              />
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul>
    );
  }
}
export default NavbarUser;
