export const modifiedNameManu = (nameMenu, menuCode) => {
  let obj = {
    nameMenuHeader: '',
    nameMenuSubHeader: ''
  }

  obj.nameMenuHeader = nameMenu
  obj.nameMenuSubHeader = nameMenu
  return obj
}
